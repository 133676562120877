// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ContactList, IAppointmentData } from "../../../components/src/CommonTypes";
export const configJSON = require("./config.js");
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
import { 
    getUpcomingAppointments, 
    fetchContacts, 
    onSearchContact , 
    searchContactApiCallHandler,
    handleFetchContactsAPICall,
    handleGetUpcomingAppointmentsAPICall,
    searchAppointments,
    createChat,
    fetchPhoneBookContacts,
    handleFetchPhoneBookContactsAPICall
} from "./actions";
// Customizable Area End


export const chats = [
    {
        id: 1,
        chat_name: "Gracci Teens",
        type: "event",
        event_name: "Design Meetup"
    },
    {
        id: 2,
        chat_name: "Rocky Time",
        type: "event",
        event_name: "Design Meetup"
    },
    {
        id: 3,
        chat_name: "Deals and Damage",
        type: "appointment",
        appointment_name: "Scrum Call"
    },
    {
        id: 4,
        chat_name: "Anime News",
        type: "event",
        event_name: "Design Meetup"
    },
    {
        id: 5,
        chat_name: "Playtime",
        type: "appointment",
        appointment_name: "Scrum Call"
    },
    {
        id: 6,
        chat_name: "Gracci Teens",
        type: "event",
        event_name: "Design Meetup"
    },
    {
        id: 7,
        chat_name: "Rocky Time",
        type: "event",
        event_name: "Design Meetup"
    },
    {
        id: 8,
        chat_name: "Deals and Damage",
        type: "appointment",
        appointment_name: "Scrum Call"
    },
    {
        id: 9,
        chat_name: "Anime News",
        type: "event",
        event_name: "Design Meetup"
    },
    {
        id: 10,
        chat_name: "Playtime",
        type: "appointment",
        appointment_name: "Scrum Call"
    },
]

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    activeTab: "all" | "appointments" | "events";
    searchString: string;
    selectedGroupChatId: number | string;
    openStartNewChatForm: boolean;
    contactList: ContactList[];
    appointments: IAppointmentData[]
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GroupChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    searchContactApiCallId: string = "";
    fetchContactsApiCallId: string = "";
    getUpcomingAppointmentsApiCallId: string = "";
    createChatApiCallId:string = "";
    fetchPhoneBookApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            activeTab: "all",
            searchString: "",
            selectedGroupChatId: "",
            openStartNewChatForm: false,
            contactList: [],
            appointments: []
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = async () => {
        const token = await helper.getStorageData("authToken");
        this.setState({
            token: token
        },()=>{
            this.fetchContacts();
            this.getUpcomingAppointments()
        })
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.fetchContactsApiCallId:
                    this.handleFetchContactsAPICall(message)
                    break;
                case this.searchContactApiCallId:
                    this.searchContactApiCallHandler(message)
                    break;
                case this.getUpcomingAppointmentsApiCallId:
                    this.handleGetUpcomingAppointmentsApiCall(message);
                    break;
                case this.createChatApiCallId:
                    this.onCreateChatApiHandler(message);
                    break;
                case this.fetchPhoneBookApiCallId:
                    this.handleFetchPhoneBookContactsApiCal(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    changeTab = (newTab: "all" | "appointments" | "events") => {
        this.setState({
            activeTab: newTab
        })
    }

    goToChatView = (chatId: number) => {
        this.props.navigation.history.push(`/chats?id=${chatId}`)
        this.setState({
            selectedGroupChatId: chatId
        })
    }

    onChangeHandler = (value: string) => {
        this.setState({
            searchString: value
        })
    }

    toggleStartNewChatForm = ()=>{
        this.setState(prevState=>{
            return {
                openStartNewChatForm: !prevState.openStartNewChatForm
            }
        })
    }

    onSearchContact = async (contact: string) => {
        if(contact){
            this.searchContactApiCallId = await onSearchContact({
                token: this.state.token,
                query: contact
            })
        }else{
            this.fetchContacts()
        }
    }

    searchContactApiCallHandler = (message: Message) => {
        const responseJson = searchContactApiCallHandler(message);
        this.setState({
            contactList: responseJson.data
        })
    }

    fetchContacts = async () => {
        this.fetchContactsApiCallId = await fetchContacts({
            token: this.state.token
        })
    }

    handleFetchContactsAPICall = async (message: Message) => {
        const responseJson = handleFetchContactsAPICall(message);
        this.setState({
            contactList: responseJson.data
        })
        this.fetchPhoneBookContacts();
    }

    fetchPhoneBookContacts = async ()=>{
        this.fetchPhoneBookApiCallId = await fetchPhoneBookContacts({
            token: this.state.token
        })  
    }

    handleFetchPhoneBookContactsApiCal = (message: Message) => {
        const responseJson = handleFetchPhoneBookContactsAPICall(message);
        const {
            contactList
        } = this.state;
        this.setState({
            contactList: [...responseJson.data, ...contactList]
        })
    }


    getUpcomingAppointments = async ()=>{
        this.getUpcomingAppointmentsApiCallId = await getUpcomingAppointments({
            token: this.state.token,
            page_no: 1,
            per_page: 10
        })
    }

    handleGetUpcomingAppointmentsApiCall = (message: Message)=>{
        const response = handleGetUpcomingAppointmentsAPICall(message);
        this.setState({
            appointments: response.data
        })
    }

    onSearchAppointments = async (query: string)=>{
        if(query){
            this.getUpcomingAppointmentsApiCallId = await searchAppointments({
                token: this.state.token,
                query
            })
        }else{
            this.getUpcomingAppointments();
        }
    }

    onCreateChat = async (body: any)=>{
        this.toggleStartNewChatForm();
        helper.showLoader();
        
        this.createChatApiCallId = await createChat({
            token: this.state.token,
            body
        })
    }

    onCreateChatApiHandler = (message: Message)=>{
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.errors){
            helper.showErrorToast(responseJson.errors)
        }else{
            helper.showErrorToast("Something went wrong please try again!")
        }
        helper.hideLoader();
    }
    // Customizable Area End
}