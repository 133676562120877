// Customizable Area Start
import React, { useState } from "react";
import { Box, Typography, styled } from "@material-ui/core";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { commentIcon, likeIcon, pdfIconSvg } from "../assets";
import { IComment, IMentionInputType, IPost } from "../../../../components/src/CommonTypes";
import CommentAddModal from "../../../comments/src/components/CommentAddModal.web";
import LikedUserListModal from "./LikedUserListModal.web";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");

interface PostDetailProps {
    recentPost: IPost;
    userId?: number;
    onViewFeed: () => void;
    onLikePost: (postId: string) => void;
    onDisLikePost: (postId: string) => void;
    onClickComment: (postId: string) => void;
    commentsList?: IComment[];
    onAddComment: (event: {text: string, postId: string}) => void;
    onDeleteComment: (event: string) => void;
    isLoadingCommentsList: boolean;
    onCloseCommentModal: () => void;
    handleFetchNextComments?: () => void;
    recentPostLikedUserList?: { id: string | number, attributes: { like_by_name: string} }[]
    setLikedUsers?: (event?: {[key: string]: string}[])=> void
    fetchLikedUsers?: (postId: number |  string) => void;
    commentMentionesUserList?: IMentionInputType[];
    fetchMentionUsersList?: (query: string) => void;
}


const RecentPostCard: React.FC<PostDetailProps> = (props: PostDetailProps) => {
    const { recentPost, userId, onViewFeed, onDisLikePost, onLikePost, isLoadingCommentsList, onAddComment, onClickComment, onCloseCommentModal, onDeleteComment, commentsList, handleFetchNextComments, recentPostLikedUserList, setLikedUsers, fetchLikedUsers, commentMentionesUserList } = props;
    
    const [openPostCommentModal, setOpenPostCommentModal] = useState<HTMLImageElement | null>(null);

    const onAddPostCommentEventHandler = (text: string)=>{
        onAddComment({text, postId: recentPost.id})
      }

    const pauseVideoOnSliderChange = () => {
        const videos = document.querySelectorAll("video");
        if(videos.length){
            videos.forEach((video) => video.pause());
        }
    }

    const onClickShowLikedUsersList = ()=>{
        fetchLikedUsers?.(recentPost.id)
    }

    return (
        <StyledRecentPostDetail className="RecentPost_Container">
            <Box className="recent-post-main-container">
                <Box className="recent-post-container-header">
                   <Typography className="header" component={"span"}>Recent Post</Typography>
                   <Typography onClick={onViewFeed} className="header link cursor-pointer" component={"span"}>View Feed</Typography>
                </Box>
                <Box maxHeight={"410px"} overflow={"auto"} className="recent-post-main-card show-scrollbar">
                    <Box className="recent-post-created-details">
                        <Box className="recent-post-owner-details">
                            <Box className="recent-avatar">{recentPost.attributes.account_info.full_name[0].toUpperCase()}</Box>
                            <Box className="recent-post-creator-detail-box" display={"flex"}>
                                <Typography className="recent-post-creator-name" component={"span"}>{recentPost.attributes.account_info.full_name}</Typography>
                                <Typography className="recent-post-create-time" component={"span"}>{recentPost.attributes.created_at}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box position={"relative"}>
                        <Slider beforeChange={pauseVideoOnSliderChange} initialSlide={0} draggable={false} infinite={false} touchMove={true} touchThreshold={5} slidesToScroll={1} slidesToShow={1} swipeToSlide dots arrows={false}>
                            {recentPost.attributes.images_and_videos.map((fileItem) => <Box className="recent-post-files" key={fileItem.id}>
                                {fileItem.url.includes("type=image") && <img className="recent-post-img" src={fileItem.url} alt="uploaded-post" />}
                                {fileItem.url.includes("type=application") && <Box bgcolor={"rgba(0, 0, 0, 0.2)"} borderRadius={"12px"} display="flex" alignItems="center" justifyContent={"center"} className="recent-post-img"><img className="cursor-pointer" id="pdf-placeholder" src={pdfIconSvg} alt="uploaded-post" onClick={() => helper.openFileInNewTab(fileItem.url)} /></Box>}
                                {fileItem.url.includes("type=video") && <video controls controlsList="nodownload" className="recent-post-img" src={fileItem.url} />}</Box>
                            )}
                        </Slider>
                        <Box className="recent_post_like_comment_action">
                            {recentPost.attributes.liked_by_me ? <FavoriteIcon data-test-id="liked" onClick={() => onDisLikePost(recentPost.id)} className="recent_post_liked_by_me cursor-pointer" /> : <img onClick={() => onLikePost(recentPost.id)} id="not-liked" className="cursor-pointer" src={likeIcon} alt="like-post" />}
                            <img id="comment-modal-icon" className="cursor-pointer" onClick={(event) => {
                                    onClickComment(recentPost.id);
                                    setOpenPostCommentModal(event.currentTarget)
                                }} src={commentIcon} alt="comment-post" />
                        </Box>
                    </Box>
                    <Box className="recent_post_like_comment">
                        <Typography className="text-underline" id="show_liked_users_list" onClick={onClickShowLikedUsersList} component={"span"}>{recentPost.attributes.total_likes} Likes</Typography>
                        <Typography component={"span"}>.</Typography>
                        <Typography component={"span"}>{recentPost.attributes.comments_count} Comments</Typography>
                    </Box>
                    <Box className="recent_post_comment">
                        <Typography component={"span"}>{recentPost.attributes.body}</Typography>
                    </Box>
                </Box>
                <CommentAddModal
                    onClose={() => {
                        setOpenPostCommentModal(null);
                        onCloseCommentModal();
                    }}
                    commentsList={commentsList as IComment[]}
                    open={openPostCommentModal}
                    onAddComment={onAddPostCommentEventHandler}
                    onDeleteComment={onDeleteComment}
                    userId={userId}
                    mentionesUserList={commentMentionesUserList}
                    handleFetchNextPageComments={handleFetchNextComments}
                    isLoadingComments={isLoadingCommentsList}
                />
            </Box>
            {
                recentPostLikedUserList && (
                    <LikedUserListModal list={recentPostLikedUserList} open={Boolean(recentPostLikedUserList)} onClose={()=> setLikedUsers?.(undefined)} />
                )
            }
        </StyledRecentPostDetail>
    )
}

const StyledRecentPostDetail = styled("div")({
    "&.RecentPost_Container": {
        display: "flex",
        gap: "0.8rem",
        flexDirection: "column",
        "& .recent-post-main-container": {
            display: "flex",
            padding: "1rem",
            gap: "1rem",
            flexDirection: "column",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            borderRadius: "1rem",
            "& .recent-post-container-header": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                "& .header": {
                    fontSize: "16px",
                    color: "rgba(255, 255, 255, 0.8)",
                },
                "& .link": {
                    color: "rgba(247, 83, 103, 1) !important"
                }
            }
        },
        "& .recent-post-main-card": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            padding: "20px",
            borderRadius: "0.5rem",
            "& .recent-post-files": {
                width: "338px",
                height: "338px",
                "& .recent-post-img": {
                    width: "100%",
                    height: "338px",
                    objectFit: "cover",
                    borderRadius: "12px",
                }
            },
            "& .recent-post-created-details": {
                display: "flex",
                gap: "0.8rem",
                paddingBottom: "20px",
                "& .recent-post-owner-details": {
                    display: "flex",
                    color: "rgba(255, 255, 255, 1)",
                    alignItems: "center",
                    gap: "0.5rem",
                    "& .recent-post-creator-detail-box": {
                        gap: "12px",
                        alignItems: "center",
                    },
                    "& .recent-avatar": {
                        justifyContent: "center",
                        alignItems: "center",
                        color: "rgba(37, 236, 242, 1)",
                        display: "flex",
                        width: "36px",
                        height: "36px",
                        border: "1px solid rgba(37, 236, 242, 1)",
                        borderRadius: "100%"
                    },
                    "& .recent-post-creator-name": {
                        fontSize: "14px",
                    },
                    "& .recent-post-create-time": {
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "12px",
                    },
                }
            },
            "& .recent_post_like_comment_action": {
                display: "flex",
                gap: "15px",
                alignItems: "center",
                padding: "7px 0px",
                zIndex: 5,
                width: "56px",
                position: "relative",
                "& .recent_post_liked_by_me": { 
                    color: "red"
                }
            },
            "& .recent_post_like_comment": {
                alignItems: "center",
                display: "flex",
                gap: "8px",
                "& span": {
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "12px",
                }
            },
            "& .recent_post_comment": {
                marginTop: "8px",
                "& span": {
                    fontSize: "12px",
                    color: "rgba(255, 255, 255, 0.8)",
                }
            },
            "& .slick-dots": {
                "& li": {
                    width: "10px",
                    "& button": {
                        "&:before": {
                            color: "rgba(255, 255, 255, 1)",
                        }
                    }
                }
            }
        }
    }
})

export default RecentPostCard;
// Customizable Area End