// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IComment, IMentionInputType, IPost } from "../../../components/src/CommonTypes";
const {
  HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    // Customizable Area Start
  navigation?: any;
  id?: string;
  groupId: number;
  groupPostsList: Array<IPost>;
  commentMentionsList: IMentionInputType[];
  handleFetchNextPagePosts: () => void;
  handleUpdateCommentsCount: (postId: number | string, action?: string) => void;
  handleCommentSectionShow: (postId: number | string) => void;
  handleCommentSectionHide: (postId: number | string) => void;
  handleDeleteGroupPost: (postId: number | string, groupId: string | number) => void;
  handleUpdateLikeGroupPost: (postId: number | string) => void;
  handleEditGroupPost: (postDetails: IPost) => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string | null;
  userId: number | undefined;
  userName: string | undefined;
  page: number;
  isAllGroupPostsFetched: boolean;
  currentLikedPostId: number | string;
  currentCommentModal: {
    id: string;
  } | null;
  commentsCurrentPage: number;
  currentGroupPostCommentList: IComment[];
  isLoadingComments: boolean;
  isAllGroupPostCommentsFetched: boolean;
  currentCommentedPostId: number | string;
  deletedCommentId: number | string;
  confirmationGroupPostDeletePopUpData: {
    header: string;
    message: string;
    type: string;
    postId: string;
  } | null;
  groupPostLikedUserList: { id: string | number, attributes: { like_by_name: string} }[] | undefined;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GroupPostsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  likeGroupPostApiCallId: string = "";
  getGroupPostCommentsApiCallId: string = "";
  addGroupPostCommentApiCallId: string = "";
  deleteGroupPostCommentApiCallId: string = "";
  groupPostLikedUsersListApiCallId: string = "";
  getSearchMentionsListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      userId: undefined,
      page: 1,
      isAllGroupPostsFetched: false,
      currentLikedPostId: "",
      currentCommentModal: null,
      commentsCurrentPage: 1,
      currentGroupPostCommentList: [],
      isLoadingComments: false,
      userName: "",
      isAllGroupPostCommentsFetched: false,
      currentCommentedPostId: "", 
      deletedCommentId: "",
      confirmationGroupPostDeletePopUpData: null,
      groupPostLikedUserList: undefined,
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    (async () => {
      const userData = await helper.getUserData();
      if (userData) {
        this.setState({ userId: userData.id, userName: userData.full_name });
      }
    })();
    // Customizable Area End
  }

  // Customizable Area Start

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (!token) {
        token = await getStorageData("authToken");
      }
      this.setToken(token);
    }
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const webApiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        switch(webApiRequestCallId){
            case this.getGroupPostCommentsApiCallId:
             this.handleGetGroupPostCommentsApiCall(message);
             break;
            case this.addGroupPostCommentApiCallId:
             this.handleAddGroupPostCommentApiCall(message);
             break;
            case this.deleteGroupPostCommentApiCallId:
             this.handleDeletePostGroupCommentApiCall(message);
             break;
            case this.likeGroupPostApiCallId:
              this.likeGroupPostApiCallHandler(message);
              break;
            case this.groupPostLikedUsersListApiCallId:
              this.handleGetGroupPostLikedUsersListCall(message);
              break;
        }
    }

    // Customizable Area End
  };

  setToken = async (token: string | null) => {
    this.setState({ token });
  };

  handleHideCommentsSection = (postId: number |  string) => {
    this.setState({ currentCommentedPostId: "", currentGroupPostCommentList: []})
    this.props.handleCommentSectionHide(postId)
  }

  scrollToTopOnGroupPostComments = () => {
    const topDiv = document.getElementById("group-post-comment-top-div");
    if (topDiv) {
      topDiv.scrollIntoView({
        behavior: 'smooth', block: 'nearest', inline: 'start'
      })
    }
  }

  getGroupPostComments = async(groupPostId: number | string) => {
    this.props.handleCommentSectionShow(groupPostId)
    this.setState({
      isLoadingComments: true,
      currentCommentedPostId: groupPostId,
      currentGroupPostCommentList: [],
    }, async() => {
      this.getGroupPostCommentsApiCallId = await helper.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.apiMethod,
        token: this.state.token,
        endPoint: `${configJSON.commentsListApiEndpoint}?commentable_id=${groupPostId}&order=desc`,
      });
    });
  }

  handleGetGroupPostCommentsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.meta?.next_page) {
      this.setState({ isAllGroupPostCommentsFetched: true })
    }
    if(responseJson?.data?.length){
      this.setState({
        currentGroupPostCommentList: [ ...responseJson.data]
      })
    } else{
      this.setState({
        currentGroupPostCommentList: []
      })
    }
    this.setState({
      isLoadingComments: false
    })
  };

  addGroupPostComment = async(groupPostId: number | string, comment: { text: string }) => {
    this.setState({
      isLoadingComments: true,
      currentCommentedPostId: groupPostId
    }, async() => {
      const body = {
        comment: {
          commentable_type: "BxBlockPosts::Post",
          commentable_id: groupPostId,
          comment: comment.text
        }
      }
      this.addGroupPostCommentApiCallId = await helper.apiCall({
        method: configJSON.postApiMethod,
        contentType: configJSON.apiContentType,
        token: this.state.token,
        endPoint: `${configJSON.commentsListApiEndpoint}`,
        body
      });
    })
  }

  handleAddGroupPostCommentApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson?.data){
      const { currentGroupPostCommentList, currentCommentedPostId } = this.state;
      let updatedCommentsList = [ { ...responseJson.data}, ...currentGroupPostCommentList];
      this.setState({
        currentGroupPostCommentList: updatedCommentsList,
      })
      this.props.handleUpdateCommentsCount(currentCommentedPostId)
      this.scrollToTopOnGroupPostComments();
    } else {
      const errorAddPostToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorAddPostToastMsg);
    }
    this.setState({
      isLoadingComments: false
    })
  }

  onDeleteGroupPostComment = async (commentId: string | number, postId: string | number) => {
    this.setState({
      isLoadingComments: true,
      currentCommentedPostId: postId,
      deletedCommentId: commentId,
    });
    const { token } = this.state;
    this.deleteGroupPostCommentApiCallId = await helper.apiCall({
      method: configJSON.deleteApiMethod,
      endPoint: `${configJSON.commentsListApiEndpoint}/${commentId}?commentable_id=${postId}`,
      contentType: configJSON.apiContentType,
      token: token,
    });
  };

  handleDeletePostGroupCommentApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.message) {
      const { deletedCommentId, currentGroupPostCommentList , currentCommentedPostId} = this.state;
      const updatedCommentsList = currentGroupPostCommentList.filter((item) => item.id !== deletedCommentId)
      this.setState({
        currentGroupPostCommentList: updatedCommentsList,
      });
      this.props.handleUpdateCommentsCount(currentCommentedPostId, "decrease")
    } else {
      const errorDelPostToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorDelPostToastMsg);
    }
    this.setState({
      isLoadingComments: false,
      deletedCommentId: "",
      currentCommentedPostId: "",
    });
  };

  openPostDeleteModal = (title: string, postId: string | number) => {
    this.setState({
      confirmationGroupPostDeletePopUpData: {
        header: "Delete Post?",
        message: `Are you sure that you want to delete this ${title}?`,
        type: "delete",
        postId: postId as string,
      },
    });
  };

  onCloseDeleteConfirmationModal = () => {
    this.setState({
      confirmationGroupPostDeletePopUpData: null,
    });
  };

  onAcceptGroupPostDeleteConfirmation = async () => {
    if (this.state.confirmationGroupPostDeletePopUpData) {
      const { groupId } = this.props;
      const { type, postId } = this.state.confirmationGroupPostDeletePopUpData;
      if (type === "delete") {
        this.props.handleDeleteGroupPost(postId, groupId);
      }
      this.onCloseDeleteConfirmationModal()
    }
  }
  
  onLikeGroupPost = async (postId: string) => {
    this.setState({
      currentLikedPostId: postId,
    });
    const request = {
      likeable_id: postId,
      like_type: configJSON.likeType,
    };
    this.likeGroupPostApiCallId = await helper.apiCall({
      method: configJSON.postApiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likePostEndPoint}`,
      body: request,
    });
  };

  likeGroupPostApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson.message || responseJson.data)) {
      const { currentLikedPostId } = this.state;
      this.props.handleUpdateLikeGroupPost(currentLikedPostId);
    } else {
      const errorLikePostToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorLikePostToastMsg);
    }
    this.setState({ currentLikedPostId: "" })
  };

  handleGetGroupPostLikedUsersListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.data) {
      this.setState({
        groupPostLikedUserList: [...responseJson.data.data],
      });
    } else {
      this.setState({ groupPostLikedUserList: [] });
    }
    helper.hideLoader();
  };

  getGroupPostLikedUsersList = async(groupPostId: number | string) => {
    helper.showLoader();
    this.groupPostLikedUsersListApiCallId = await helper.apiCall({
      method: configJSON.apiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likedUsersListApiEndpoint}?post_id=${groupPostId}`,
    });
  }

  setLikedUserList = () => {
    this.setState({
      groupPostLikedUserList: undefined,
    });
  };

  // Customizable Area End
}
