// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { IComment, IPost } from "../../../components/src/CommonTypes";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    postDetail: IPost | null;
    postId: string;
    commentId: string;
    typeOfPost: "event" | "group" | "";
    typeId: string;
    currentLikedPostId: string;
    currentPostLikedUserList: { id: string | number, attributes: { like_by_name: string } }[] | undefined;
    currentCommentModal: {
        id: string;
    } | null;
    commentsCurrentPage: number;
    currentPostCommentList: IComment[];
    isLoadingComments: boolean;
    isAllCommentsFetched: boolean;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UpcomingEventsListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getPostDetailApiCallId: string = "";
    likeCurrentPostApiCallId: string = "";
    getCurrentPostCommentsApiCallId: string = "";
    createCommentApiCallId: string = "";
    deleteCommentApiCallId: string = "";
    currentPostLikedUsersListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            commentId: "",
            postDetail: null,
            postId: "",
            typeId: "",
            typeOfPost: "",
            commentsCurrentPage: 1,
            currentCommentModal: null,
            currentLikedPostId: "",
            currentPostCommentList: [],
            currentPostLikedUserList: undefined,
            isAllCommentsFetched: false,
            isLoadingComments: false,
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        this.getPostDetail();
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = await getStorageData("authToken");
            this.setState({ token });
        }

        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            switch (apiCallId) {
                case this.getPostDetailApiCallId:
                    this.handleGetPostDetailCall(message);
                    break;
                case this.currentPostLikedUsersListApiCallId:
                    this.handleGetPostLikedUsersListCall(message);
                    break;
                case this.likeCurrentPostApiCallId:
                    this.likePostApiCallHandler(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start

    handleGetPostDetailCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data) {
            this.setState({
                postDetail: responseJson.data,
            });
        } else {
            this.setState({ postDetail: null });
            const errorPostToastMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong, please try again!";
            helper.showErrorToast(errorPostToastMsg);
        }
        helper.hideLoader();
    };

    getPostDetail = async () => {
        helper.showLoader();
        const token = await getStorageData("authToken");
        const type = helper.getParamFromRoute(this.props.navigation, "type")
        const typeId = helper.getParamFromRoute(this.props.navigation, "typeId");
        const postId = helper.getParamFromRoute(this.props.navigation, "postId");
        this.setState({ postId, typeId, typeOfPost: type });
        const type_id = type === "event" ? "event_id" : "group_id"
        this.getPostDetailApiCallId = await helper.apiCall({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.validationApiContentType,
            token,
            endPoint: `${configJSON.getPostDetailEndpoint}/${postId}?${type_id}=${typeId}`,
        });
    };

    onLikeCurrentPost = async () => {
        const body = {
            likeable_id: this.state.postId,
            like_type: configJSON.likeType,
        };
        this.likeCurrentPostApiCallId = await helper.apiCall({
            method: configJSON.PostAPiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likePostEndPoint}`,
            body,
        });
    };

    likePostApiCallHandler = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && (responseJson.message || responseJson.data)) {
            const { postDetail } = this.state;
            if (postDetail) {
                let updatedPostDetail = { ...postDetail };
                if (updatedPostDetail.attributes.liked_by_me) {
                    updatedPostDetail.attributes.total_likes -= 1;
                } else {
                    updatedPostDetail.attributes.total_likes += 1;
                }
                updatedPostDetail.attributes.liked_by_me = !updatedPostDetail.attributes.liked_by_me;
                this.setState({
                    postDetail: { ...updatedPostDetail },
                });
            }
        } else {
            const likePostErrMsg = responseJson?.errors?.[0]?.message
              ? responseJson.errors[0].message
              : "Something went wrong, please try again!";
            helper.showErrorToast(likePostErrMsg);
          }
    };

    handleGetPostLikedUsersListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data?.data) {
            this.setState({
                currentPostLikedUserList: [...responseJson.data.data],
            });
        } else {
            this.setState({ currentPostLikedUserList: [] });
        }
        helper.hideLoader();
    };

    getLikedUsersList = async (postId: number | string) => {
        helper.showLoader();
        this.currentPostLikedUsersListApiCallId = await helper.apiCall({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likedUsersListApiEndpoint}?post_id=${postId}`,
        });
    }

    setCurrentPostLikedUserList = () => {
        this.setState({
            currentPostLikedUserList: undefined,
        });
    };

    // Customizable Area End
}