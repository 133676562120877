import React from "react";
import { styled } from "@material-ui/styles";
interface Props {
    text?: string | null;
    image_url?: string | null;
}


const AvatarComponent: React.FC<Props> = (props: Props) => {
    const { text, image_url } = props;

    return (
        <StyledAvatar className="AvatarComponent_StyledAvatar">
            {
                image_url && (
                    <img src={image_url} />
                )
            }
            {
                !image_url && text && (
                    <div className="avatar-text">{text[0]}</div>
                )
            }
            {
                !image_url && !text && (
                    <div className="avatar-text"></div>
                )
            }
        </StyledAvatar>
    )
}

const StyledAvatar = styled("div")({
    "&.AvatarComponent_StyledAvatar":{
        "& > img":{
            height: "2rem",
            width: "2rem",
            borderRadius: "100%"
        }
    }
})

export default AvatarComponent;