const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
const configJSON = require("./config.js");
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";


interface IApiCallData {
    token: string | null,
    page_no?: number;
    per_page?: number;
    query?: string;
    body?: any;
    endPoint?: string;
}

export const actions = {};

export const handleEventDetailCall = (message: Message) => {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
        return {
            data: responseJson.data
        }
    } else {
        return {
            data: null
        }
    }
}

export const getEventDetail = async (apiCallData: IApiCallData) => {
    const {
        token,
        endPoint
    } = apiCallData;
    return await helper.apiCall({
        method: configJSON.validationApiMethodType,
        endPoint: `${configJSON.eventsEndpoint}/${endPoint}`,
        contentType: configJSON.appointmentApiContentType,
        token: token
    })
}