// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Menu, Typography, styled } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { MoreIcon, commentIcon, likeIcon } from "../assets";
import { IComment, IMentionInputType, IPost } from "../../../../components/src/CommonTypes";
import { pdfPlaceholder } from "../../../postcreation/src/assets";
import LikedUserListModal from "./LikedUserListModal.web";
import CommentsList from "../../../comments/src/CommentsList.web";
import { Mention, MentionsInput } from "react-mentions";
import EmojisPicker from "../../../emojis/src/EmojisPicker.web";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");
const { useInView } = require("react-intersection-observer");
import Avatar from "./Avatar.web";

interface GroupPostDetailProps {
    groupPostDetail: IPost;
    onDeleteGroupPost?: (title: string, eventId: string | number) => void;
    userId?: number;
    userName?: string;
    onEditGroupPost?: (postDetails: IPost) => void;
    isLastElement: boolean;
    handleFetchNextPagePosts: () => void;
    onLikeGroupPost?: (postId: string)=> void;
    fetchLikedUsers?: (postId: number |  string) => void;
    likedUserList?: { id: string | number, attributes: { like_by_name: string} }[];
    setLikedUsers?: (event?: {[key: string]: string}[]) => void
    onOpenCommentsSection?: (postId: string, pageNum?: number) => void
    onCloseCommentsSection?: (postId: string) => void;
    isCommentSectionOpen: boolean;
    handleFetchNextPageComments?: (postId: number | string, pageNum: number) => void;
    comments?: IComment[];
    onAddComment?:(postId: number |  string, comment:{text: string})=> void;
    onDeleteComment?: (commentId: string | number) => void;
    isLoadingComments?: boolean;
    isFromGroupPost?: boolean;
    mentionsListForComments?: IMentionInputType[];
}

const GroupPostListCard: React.FC<GroupPostDetailProps> = (props: GroupPostDetailProps) => {
    const { 
        groupPostDetail, 
        onDeleteGroupPost, 
        userId, 
        onEditGroupPost, 
        isLastElement, 
        handleFetchNextPagePosts, 
        onLikeGroupPost, 
        likedUserList, 
        setLikedUsers,
        userName,
        comments,
        onOpenCommentsSection,
        isLoadingComments,
        onAddComment,
        isCommentSectionOpen,
        onCloseCommentsSection,
        onDeleteComment,
        fetchLikedUsers,
        mentionsListForComments = [],
    } = props;
    const [optionGroupPostDropdownMenuAnchorEle, setOptionGroupPostDropdownMenuAnchorEle] = useState<HTMLElement | null>(null);
    const [endOfPosts, isEndOfPostsVisible] = useInView({
        threshold: 0,
    });
    const [replyCommentText, setReplyCommentText] = useState<string>("");
    const [emojiIconButtonElement, setEmojiIconButtomElement] = useState<HTMLButtonElement | null>(null);
    const groupPostCommentFieldRef = useRef<HTMLInputElement | null>(null);
    
    const openPostOptionMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setOptionGroupPostDropdownMenuAnchorEle(event.currentTarget)
    }

    const closePostOptionMenu = () => {
        setOptionGroupPostDropdownMenuAnchorEle(null)
    }

    const onClickViewLikesListHandler = ()=>{
        fetchLikedUsers?.(groupPostDetail.id)
    }

    const GroupPostOptionDropdown = () => {
        return (
            <CustomMenuGroupPost
                className="Custom_Menu_Group_Post"
                data-test-id="custom-menu-group-post"
                open={Boolean(optionGroupPostDropdownMenuAnchorEle)}
                onClose={closePostOptionMenu}
                anchorEl={optionGroupPostDropdownMenuAnchorEle}
                PaperProps={{
                    style: {
                        transform: "translateY(0.5rem)"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
            >
                <StyledGroupPostOptionDropdown id="group-post-options-menu" className="GroupPostOptionDropdown_Web">
                            <Box id="edit-group-post-icon" className="item" onClick={() => {
                                if (onEditGroupPost) {
                                    onEditGroupPost(groupPostDetail);
                                    setOptionGroupPostDropdownMenuAnchorEle(null);
                                }
                            }}>
                                <svg id="edit-group-post-icon-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.99997 16H3.2615L13.4981 5.7634L12.2366 4.50188L1.99997 14.7385V16ZM0.5 17.5V14.1154L13.6904 0.930775C13.8416 0.793426 14.0086 0.687292 14.1913 0.612375C14.374 0.537458 14.5656 0.5 14.7661 0.5C14.9666 0.5 15.1608 0.535584 15.3488 0.60675C15.5368 0.6779 15.7032 0.791034 15.848 0.94615L17.0692 2.18268C17.2243 2.32754 17.3349 2.49424 17.4009 2.68278C17.4669 2.87129 17.5 3.05981 17.5 3.24833C17.5 3.44941 17.4656 3.64131 17.3969 3.82403C17.3283 4.00676 17.219 4.17373 17.0692 4.32495L3.88458 17.5H0.5ZM12.8563 5.1437L12.2366 4.50188L13.4981 5.7634L12.8563 5.1437Z" fill="white" />
                                </svg>
                                Edit
                            </Box>
                            <Box id="delete-group-post-icon" className="item"
                                onClick={() => {
                                    if (onDeleteGroupPost) {
                                        onDeleteGroupPost("Post", groupPostDetail.id);
                                        setOptionGroupPostDropdownMenuAnchorEle(null);
                                    }
                                }}
                            >
                                <svg id="delete-post-icon-svg" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.3077 17.5017C2.80898 17.5017 2.38302 17.3251 2.02982 16.9719C1.67661 16.6187 1.5 16.1928 1.5 15.694V3.00176H0.5V1.50179H4.99997V0.617188H11V1.50179H15.5V3.00176H14.5V15.694C14.5 16.1992 14.325 16.6267 13.975 16.9767C13.625 17.3267 13.1974 17.5017 12.6922 17.5017H3.3077ZM13 3.00176H2.99997V15.694C2.99997 15.7838 3.02883 15.8575 3.08652 15.9152C3.14422 15.9729 3.21795 16.0018 3.3077 16.0018H12.6922C12.7692 16.0018 12.8397 15.9697 12.9038 15.9056C12.9679 15.8415 13 15.771 13 15.694V3.00176ZM5.40385 14.0018H6.90382V5.00176H5.40385V14.0018ZM9.09613 14.0018H10.5961V5.00176H9.09613V14.0018Z" fill="white" />
                                </svg>
                                Delete
                            </Box>
                </StyledGroupPostOptionDropdown>
            </CustomMenuGroupPost>
        )
    }

    const onAddReplyCommentHandler = ()=>{
        if(!replyCommentText || replyCommentText.trim() === "") return;
        if(onAddComment) {
            const cleanedText = replyCommentText.replace(/@\[(.*?)\]\(\d+\)/g, '@$1');
            const comment = {
                text: cleanedText
            }
            onAddComment(groupPostDetail.id, comment);
        }
        setReplyCommentText("")
    }

    const handleKeyPressCommentReplyField = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
         onAddReplyCommentHandler()
        }
      }

    useEffect(() => {
        if(isEndOfPostsVisible){
            handleFetchNextPagePosts?.()
        }
    }, [isEndOfPostsVisible])

    const openComments = () => {
        if(!isCommentSectionOpen) {
            onOpenCommentsSection?.(groupPostDetail.id)
        }
    }

    const onEmojiClick = (e: React.MouseEvent, emojiObjectItem: { emoji: string }) => {
        setReplyCommentText((prevText) => prevText + emojiObjectItem.emoji); // Add emoji to text state
    };

    const toggleCommentEmojiPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEmojiIconButtomElement(event.currentTarget)
    };

    const onCloseEmojiPicker = () => {
        setEmojiIconButtomElement(null);
        setTimeout(() => {
            if (groupPostCommentFieldRef.current) {
              groupPostCommentFieldRef.current.focus();
            }
        }, 10);
    }

    return (
        <StyledPostDetail ref={isLastElement ? endOfPosts : null} className="Group_Post_Card_Container">
            <Box className={"group-post"}>
                <Box className="group-post-detail">
                    <Box className="group-post-header-container">
                        <Box className="group-post-owner">
                            <Box className="created-by">
                                <Box className="owner-details">
                                    <Avatar 
                                      text={groupPostDetail.attributes.account_info.full_name}
                                      image_url={groupPostDetail.attributes.account_info.image_url}
                                    />
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography className="group-post-creator-name" component={"span"}>{groupPostDetail.attributes.account_info.full_name}</Typography>
                                        <Typography className="group-post-create-time" component={"span"}>{groupPostDetail.attributes.created_at}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {userId && userId === groupPostDetail.attributes.account_id && (
                            <>
                                <img data-test-id="group-post-more-icon" onClick={(event) => openPostOptionMenu(event)} src={MoreIcon} />
                                {GroupPostOptionDropdown()}
                            </>
                        )}
                    </Box>
                    <Box className="group-post-files">
                        {groupPostDetail.attributes.images_and_videos.map((item) => <Box key={item.id}>
                            {item.url.includes("type=image") && <img className="group-post-img cursor-pointer" src={item.url} alt="uploaded-post" onClick={() => helper.openFileInNewTab(item.url)} />}
                            {item.url.includes("type=application") && <img className="group-post-img cursor-pointer" src={pdfPlaceholder} alt="uploaded-post" onClick={() => helper.openFileInNewTab(item.url)} />}
                            {item.url.includes("type=video") && <video controls controlsList="nodownload" className="group-post-img" src={item.url} />}</Box>)}
                    </Box>
                    <Box className="group-post-description">
                        <Typography component={"p"}>
                            {groupPostDetail.attributes.body}
                        </Typography>
                    </Box>
                    <Box className="group_post_like_comment_action">
                        {groupPostDetail.attributes.liked_by_me ? <FavoriteIcon className="liked_by_me" onClick={()=> onLikeGroupPost?.(groupPostDetail.id)} /> : <img src={likeIcon} alt="like" onClick={()=> onLikeGroupPost?.(groupPostDetail.id)} />}
                        <img id="open-comments" onClick={() => openComments()} src={commentIcon} alt="comment" />
                    </Box>
                    <Box className="group_post_like_comment">
                        <Typography component={"span"} className="text-underline" id="likes_count" onClick={onClickViewLikesListHandler}>{groupPostDetail.attributes.total_likes} Likes</Typography>
                        <Typography component={"span"}>.</Typography>
                        {!isCommentSectionOpen ? <Typography id="open-comments-text" onClick={() => openComments()}
                            component={"span"} className="text-underline"
                        >{groupPostDetail.attributes.comments_count} Comments</Typography>
                            : <Typography id="hide-comments-text" className="text-underline" onClick={() => onCloseCommentsSection?.(groupPostDetail.id)}
                                component={"span"}
                            >hide comments</Typography>}
                    </Box>
                    {isCommentSectionOpen && 
                     <CommentsList
                        commentsList={comments}
                        id="Comments"
                        isCommentsLoading={isLoadingComments}
                        userId={userId}
                        navigation={undefined}
                        onDeleteComment={onDeleteComment}
                      />
                    }
                </Box>
                <Box className="reply_to_post">
                    <Avatar
                        text={groupPostDetail.attributes.account_info.full_name}
                        image_url={groupPostDetail.attributes.account_info.image_url}
                    />
                    <MentionsInput
                        id="reply_to_post_textfield"
                        inputRef={groupPostCommentFieldRef}
                        value={replyCommentText}
                        autoComplete="off"
                        onChange={e => {
                            const sanitizedValue = e.target.value.replace(/^\s+/, '');
                            const parsedValueEmoji = helper.parseEmojis(sanitizedValue);
                            setReplyCommentText(parsedValueEmoji)
                        }}
                        onKeyPress={handleKeyPressCommentReplyField}
                        className="reply_to_post_text_field mention-input"
                        placeholder="Reply"
                        singleLine
                        spellCheck
                        style={{
                            control: {
                                color: '#fff',
                            },
                            highlighter: {
                                color: '#fff',
                            },
                            input: {
                                color: '#fff',
                            },
                        }}
                    >
                        <Mention
                            trigger="@"
                            data={mentionsListForComments}
                            className="mentions__mention mention-highlight"
                            style={{
                                backgroundColor: 'rgba(247, 83, 103, 1)',
                            }}
                            appendSpaceOnAdd
                            displayTransform={(_, display) => `@${display}`}
                        />
                    </MentionsInput>
                    <div className="reply_to_group_post_actions">
                        <IconButton id="comment-emoji-picker-btn" onClick={toggleCommentEmojiPicker}>
                            <InsertEmoticonIcon className="insert-emoticon" />
                        </IconButton>
                        <EmojisPicker emojiRefElement={emojiIconButtonElement} onClose={() => onCloseEmojiPicker()} onEmojiClick={onEmojiClick} />
                    </div>
                </Box>
            </Box>
            {
                likedUserList && (
                    <LikedUserListModal list={likedUserList} open={Boolean(likedUserList)} onClose={()=> setLikedUsers?.(undefined)} />
                )
            }
        </StyledPostDetail>
    )
}

const StyledPostDetail = styled("div")({
    "&.Group_Post_Card_Container": {
        display: "flex",
        gap: "0.8rem",
        flexDirection: "column",
        "& .avatar": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgba(37, 236, 242, 1)",
            width: "25px",
            height: "25px",
            border: "1px solid rgba(37, 236, 242, 1)",
            borderRadius: "100%"
        },
        "& .group-post": {
            display: "flex",
            flexDirection: "column",
            background: "#1E3640",
            borderRadius: "12px",
            "& .img-container": {
                "& > img": {
                    width: "100%",
                    height: "315px",
                    borderRadius: "1rem",
                    objectFit: "fill",
                }
            },
            "& .group-post-detail": {
                flex: 1,
                padding: "16px",
                flexDirection: "column",
                gap: "10px",
                display: "flex",
                width: "inherit",
                "& .group-post-header-container": {
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    "& > img": {
                        cursor: "pointer"
                    }
                },
                "& .group-post-owner": {
                    gap: "4rem",
                    display: "flex",
                    alignItems: "center",
                    "& .created-by": {
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.8rem",
                        "& .header": {
                            fontWeight: 500,
                            fontSize: "0.875rem",
                            color: "rgba(255, 255, 255, 0.8)"
                        },
                        "& .owner-details": {
                            display: "flex",
                            color: "rgba(255, 255, 255, 1)",
                            alignItems: "center",
                            gap: "0.5rem",
                            "& .group-post-creator-name": {
                                fontSize: "14px",
                                marginRight: "15px",
                            },
                            "& .group-post-create-time": {
                                fontSize: "12px",
                                color: "rgba(255, 255, 255, 0.8)"
                            },
                        },
                    },
                    [`@media screen and (max-width: 1100px)`]: {
                        gap: "0.5rem",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                    },
                },
                "& .group-post-description": {
                    wordBreak: "break-word",
                    fontSize: "16px",
                    color: "rgba(255, 255, 255, 1)"
                },
                "& .group-post-files": {
                    display: "flex",
                    overflowX: "auto",
                    maxWidth: "95%",
                    alignItems: "center",
                    gap: "15px",
                    "& .group-post-img": {
                        width: "250px",
                        height: "160px",
                        objectFit: "cover",
                        borderRadius: "16px",
                    },
                },
                "& .group_post_like_comment": {
                    alignItems: "center",
                    display: "flex",
                    gap: "8px",
                    "& span": {
                        fontSize: "12px",
                        color: "rgba(255, 255, 255, 0.8)"
                    }
                },
                "& .group_post_like_comment_action": {
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    position: "relative",
                    "& .liked_by_me": {
                        color: "red",
                        width: "20px",
                        height: "20px",
                    },
                    "& > *":{
                       cursor: "pointer"
                    }
                }
            },
            [`@media screen and (max-width: 900px)`]: {
                "& .img-container": {
                    display: "none"
                }
            },
            "& .reply_to_post": {
                borderTop: "1px solid #2d4953",
                padding: "10px 16px",
                display: "flex",
                gap: "8px",
                "& input": {
                    width: "100%",
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 0.8)",
                    '&::placeholder': {
                        color: "rgba(255, 255, 255, 1)"
                    },
                    "&:focus": {
                        border: "none",
                        outline: 0,
                    }
                },
                "& .mention-input": {
                    height: "inherit",
                    width: "89%",
                    "& div": {
                        height: "inherit"
                    }
                },
                "& .reply_to_post_text_field__highlighter": {
                    padding: "0px 7px 0px 1px",
                },
                "& .mention-highlight": {
                    // background: "#ffeb3b !important",
                },
                "& .reply_to_post_text_field__suggestions__item--focused": {
                    background: "#2d4953"
                },
                "& .reply_to_post_text_field__suggestions": {
                    padding: "5px 0px",
                    backgroundColor: "#19282F !important",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.8) !important",
                    color: "rgba(255, 255, 255, 0.8)",
                    maxHeight: '135px',
                    minHeight: "64px",
                    minWidth: "150px !important",
                    maxWidth: "155px",
                    overflow: "scroll",
                    overflowY: "auto",
                    marginTop: "0px !important",
                    top: "-108px !important",
                    "& ul": {
                        maxHeight: "inherit",
                        overflow: "scroll"
                    },
                    "& li": {
                        padding: "5px 10px",
                        borderBottom: "1px solid #2d4953",
                        "&:hover": {
                            background: "#2d4953"
                        },
                    },
                    "& li:last-child": {
                        borderBottom: "none",
                    }
                },
            },
        },
        "& .group-post-card-bg": {
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        },
        "& .reply_to_group_post_actions":{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            "& > img":{
                cursor: "pointer"
            },
            "& > button":{
                padding: "0px"
            },
            "& .insert-emoticon": {
                color: "rgb(255 255 255 / 65%)"
            }
        }
    }
})

const StyledGroupPostOptionDropdown = styled("div")({
    "&.GroupPostOptionDropdown_Web": {
        display: "flex",
        width: "9.8125rem",
        flexDirection: "column",
        border: "1px solid #19B5B945",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        borderRadius: "0.5rem",
        padding: "0.25rem 0rem",
        "& .item": {
            display: "flex",
            color: "#ffffff",
            alignItems: "center",
            fontSize: "1rem",
            fontWeight: 400,
            gap: "0.5rem",
            cursor: "pointer",
            backgroundColor: "transparent",
            padding: "0.25rem 0.7rem",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
                backgroundColor: "#0000001A"
            }
        }
    }
})

const CustomMenuGroupPost = styled(Menu)({
    "&.Custom_Menu_Group_Post": {
        "& .MuiList-padding": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "transparent"
        }
    }
})

export default GroupPostListCard;
// Customizable Area End